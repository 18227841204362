<template>
  <el-card>
    <div slot="header"
         class="clearfix">
      <span class="title">提现管理</span>
    </div>
    <el-row>
      <withdraw-card></withdraw-card>
    </el-row>
  </el-card>
</template>

<script>
import withdrawCard from 'components/withdrawCard'
export default {
  components: {
    withdrawCard
  }
}
</script>

<style lang="less" scoped>
.title {
  color: #333;
  font-size: 18px;
}
</style>