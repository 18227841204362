<template>
  <div class="chart">
    <el-card style="margin-top:20px">
      <el-tabs v-model="activeName"
               :stretch="true"
               @tab-click="handleClick">
        <el-tab-pane label="流水"
                     name="流水">
        </el-tab-pane>
        <el-tab-pane label="开播主播"
                     name="开播主播">
        </el-tab-pane>
        <el-tab-pane label="新入驻主播"
                     name="新入驻主播">
        </el-tab-pane>
        <el-tab-pane label="收入趋势"
                     name="收入趋势">
        </el-tab-pane>
      </el-tabs>
      <diagram ref="diagram"
               :chartName="activeName"
               :xAxisData="xAxisData"
               :grid="grid"
               :seriesData="seriesData"></diagram>
    </el-card>
  </div>
</template>

<script>
import diagram from 'components/diagram'
import { mapState } from 'vuex'
export default {
  components: {
    diagram
  },
  data () {
    return {
      activeName: '流水',
      xAxisData: [],
      seriesData: [],
      grid: {
        top: 10,
        left: 40,
        right: 20,
        bottom: 20
      }
    }
  },
  methods: {
    handleClick () {
      if (this.activeName === '流水') {
        this.seriesData = this.charts.data && this.charts.data[0]
        this.grid.left = 60
      } else if (this.activeName === '开播主播') {
        this.seriesData = this.charts.data && this.charts.data[1]
        this.grid.left = 30
      } else if (this.activeName === '新入驻主播') {
        this.seriesData = this.charts.data && this.charts.data[2]
        this.grid.left = 30
      } else if (this.activeName === '收入趋势') {
        this.seriesData = this.charts.data && this.charts.data[3]
        this.grid.left = 60
      }
      this.$nextTick(() => {
        this.$refs.diagram.initChart()
      })
    }
  },
  watch: {
    charts: {
      handler: function (val) {
        this.xAxisData = val.dates
        this.handleClick()
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      charts: state => state.home.charts
    })
  }
}
</script>

<style lang="less" scoped>
.chart /deep/ .el-tabs__item {
  font-size: 16px;
  color: #999999;
}
.chart /deep/ .el-tabs__item.is-active {
  color: #333;
}
.chart /deep/ .el-tabs__nav.is-stretch {
  min-width: 480px;
}
</style>