<template>
  <div>
    <el-card class="card">
      <div slot="header"
           class="clearfix">
        <span class="title">常用功能</span>
      </div>
      <div>
        <ul>
          <li v-for="item in iconList"
              :key="item.title"
              @click="commonClick(item.path)">
            <img :src="item.imgUrl"
                 alt="常用功能图标" />
            <span class="icon-text">{{item.title}}</span>
          </li>
        </ul>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      iconList: [
        {
          title: "审核主播",
          imgUrl: require("img/anchor_input.png"),
          path: "/anchor/enrollmentList",
        },
        {
          title: "审核经纪人",
          imgUrl: require("img/audit_agent.png"),
          path: "/agentmanage/check",
        },
        {
          title: "经营分析",
          imgUrl: require("img/manage_analyze.png"),
          path: "/finance/operation",
        },
        {
          title: "联系我们",
          imgUrl: require("img/contact_us.png"),
          path: "/home/contactus",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    commonClick (path) {
      if (this.userInfo.role != "administrator" && path == "/anchor/anchorAdd") {
        this.$message.warning("您没有此权限，请联系管理员");
      } else {
        this.$router.push({ path });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  color: #333;
  font-size: 18px;
  font-weight: 500;
}
ul {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
li {
  text-align: center;
  width: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
li:nth-child(3),
li:nth-child(4) {
  margin-top: 36px;
}
img {
  display: block;
  width: 45px;
  height: 45px;
  &:hover {
    transform: scale(1.2);
    transition-duration: 0.3s;
  }
  margin-bottom: 11px;
}
.icon-text {
  font-size: 14px;
  color: #333333;
}
// .card {
//   padding-bottom: 17px;
// }
</style>