/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-10 11:05:10
 * @Module: 紧急通知
 */
 <template>
  <div>
    <el-dialog :title="data[cut].title"
               :show-close="false"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               destroy-on-close
               :visible="visible">
      <div v-html="data[cut].content" />
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="onclick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      visible: false,
      data: [],
      cut: 0
    };
  },
  mounted () {
    this._initState()
  },
  methods: {
    async _initState () {
      let { message, status_code } = await this.$api.emergencyNoticeApi()
      if (status_code == 200) {
        if (message.length) {
          this.visible = true
          message.forEach(res => {
            console.log(res)
            res.content = res.content.replace(/<img/g, "<img style='max-width:100%;height:auto;'");
          })
          this.data = message
          this.cut = 0
        }
      }
    },
    onclick () {
      if (this.cut < (this.data.length - 1)) {
        this.cut = this.cut + 1
      } else {
        this.visible = false
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>