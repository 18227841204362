<template>
  <el-card class="box-card"
           v-loading="loading"
           style="margin-top:20px;">
    <div slot="header"
         class="clearfix">
      <span class="title">直属经纪人排行（本月）</span>
    </div>
    <tablePage :tableData="tableData"
               :currentPage="currentPage"
               :height="830"
               :total="total">
      <el-table-column prop="rank"
                       type="index"
                       :index="indexMethod"
                       label="排名">
      </el-table-column>
      <el-table-column prop="name"
                       label="名字">
      </el-table-column>
      <el-table-column prop="inviter"
                       label="邀请人">
      </el-table-column>
      <el-table-column prop="broad_cast_flow"
                       sortable
                       label="本月礼物收入">
      </el-table-column>
      <el-table-column prop="anchor_count"
                       label="主播数量">
      </el-table-column>
      <el-table-column prop="distributor_count"
                       sortable
                       label="新邀请的经纪人">
      </el-table-column>
      <el-table-column prop="active_anchor_count"
                       label="本月开播人次">
      </el-table-column>
      <el-table-column prop="contract_anchor_count"
                       sortable
                       label="新增主播">
      </el-table-column>
    </tablePage>
  </el-card>
</template>

<script>
import tablePage from 'components/tablePage'
import { mapState } from 'vuex'
export default {
  components: {
    tablePage
  },
  data () {
    return {
      currentPage: 1,
      total: 10,
      loading: true
    }
  },
  methods: {
    indexMethod (index) {
      return index + 1 > 9 ? (index + 1) : '0' + (index + 1)
    }
  },
  watch: {
    tableData: {
      handler: function (val) {
        if (val) {
          this.loading = false
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      tableData: state => state.home.rank
    })
  }
}
</script>

<style lang="less" scoped>
.clearfix {
  display: flex;
  justify-content: space-between;
}
.title {
  line-height: 40px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
}
.box-card
  /deep/
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  color: #d74d38;
  background-color: #fff;
}
.box-card /deep/ el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(255, 59, 0, 1);
}
.box-card /deep/ .el-card__header {
  border-bottom: none !important;
}
</style>